import { ApolloError, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { utils, writeFile } from "xlsx";

import {
  UPDATE_SUBSCRIPTION_PRODUCTS_ORDER,
  UpdateSubscriptionProductsOrderInput,
} from "~/api/graphql/subscriptionPackage";
import { CustomButton } from "~/components/form/CustomButton";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { colors } from "~/constants/styles";
import { Product } from "~/types/data/Product.types";
import { SubscriptionCategory } from "~/types/data/SubscriptionPackage.type";
import { UpdateSubscriptionProductsOrderPriority } from "~/types/data/SubscriptionPackage.type";
import { UpdateSubscriptionProductsOrderInputObject } from "~/types/data/SubscriptionPackage.type";

import styles from "./index.module.scss";
import { ProductCard } from "./ProductCard";

interface Props {
  subscriptionCategory: SubscriptionCategory;
  subscriptionPackageId: number;
  products: Product[];
  categoryLevel: number;
  disabled: boolean;
  updateProductsSuccessHandler: (product: Product) => void;
  updateProductsOrderSuccessHandler: (products: Product[]) => void;
}
interface ProductGridCardType {
  product: Product;
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static?: boolean;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

export const ProductsList = ({
  subscriptionCategory,
  products,
  categoryLevel,
  disabled,
  subscriptionPackageId,
  updateProductsSuccessHandler,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const isOthers = subscriptionCategory.categoryId === 0;

  const filteredProducts = products.filter((product) => {
    const category =
      categoryLevel === 3 ? product.category3 : product.category2;
    return isOthers
      ? !category
      : category?.id === subscriptionCategory.categoryId;
  });

  const productsCards: ProductGridCardType[] = filteredProducts.map(
    (product, index) => {
      return {
        i: index.toString(),
        h: 1,
        w: 1,
        x: 0,
        y: index,
        product,
      };
    }
  );

  const [productsCardsState, setProductsCardsState] =
    useState<ProductGridCardType[]>(productsCards);

  const updateSubscriptionProductsOrderErrorHandler = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const updateSubscriptionProductsOrderSuccessHandler = () => {
    setOrderUpdated(false);
  };

  const [updateSubscriptionProductsOrder] = useMutation<
    undefined,
    UpdateSubscriptionProductsOrderInput
  >(UPDATE_SUBSCRIPTION_PRODUCTS_ORDER);

  const changeLayoutHandler = (layout: Layout[]) => {
    if (!orderUpdated) setOrderUpdated(true);

    const newProductsCardsState: (ProductGridCardType | undefined)[] =
      layout.map((layoutItem) => {
        const productCard = productsCardsState.find(
          (productCard) => productCard.i === layoutItem.i
        );
        if (!productCard) return;
        return {
          ...productCard,
          y: layoutItem.y,
        };
      });

    const filteredList = newProductsCardsState.filter(
      (state) => state !== undefined
    ) as ProductGridCardType[];

    setProductsCardsState(filteredList);
  };

  const changeOrderSubmitHandler = async () => {
    setIsLoading(true);
    setErrorMessage("");

    const priorities: UpdateSubscriptionProductsOrderPriority[] =
      productsCardsState.map((productCard) => {
        return {
          productParentId: productCard.product.parentId ?? "",
          subscriptionPackageId,
          priority: productCard.y + 1,
        };
      });

    const input: UpdateSubscriptionProductsOrderInputObject = {
      subscriptionId: subscriptionPackageId,
      priorities,
    };

    await updateSubscriptionProductsOrder({
      variables: {
        UpdateSubscriptionProductsOrderInput: input,
      },
      onCompleted: updateSubscriptionProductsOrderSuccessHandler,
      onError: updateSubscriptionProductsOrderErrorHandler,
    });
    setIsLoading(false);
  };

  const downloadProducts = () => {
    const mappedProducts = productsCardsState.map(({ y, product }) => {
      const {
        id,
        title,
        category3,
        category2,
        sku,
        minimumEngagement,
        points,
        secondHandPrice,
        collectionName,
      } = product;
      return {
        id,
        name: title,
        sku,
        category2: category2?.name,
        category3: category3?.name,
        thema: collectionName,
        minimum_duration: minimumEngagement,
        points,
        purchase_price: secondHandPrice,
        priority: y + 1,
      };
    });

    // create file using xlsx
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(mappedProducts);

    // make cells wrap text
    utils.book_append_sheet(wb, ws, `Products_${subscriptionCategory.name}`);
    setDownloadLoading(false);
    writeFile(wb, `Products_${subscriptionCategory.name}.xlsx`);
  };

  const exportCatalogueHandler = () => {
    setDownloadLoading(true);
    setTimeout(() => {
      downloadProducts();
    }, 500);
  };

  useEffect(() => {
    setProductsCardsState(productsCards);
  }, [subscriptionCategory]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <h3>Products for {subscriptionCategory.name}</h3>
          <CustomButton
            backgroundColor={colors.$primary}
            color="white"
            width="fit-content"
            borderRadius="8px"
            padding="1rem 1.5rem"
            type="submit"
            disabled={downloadLoading}
            height="1rem"
            onClick={() => {
              exportCatalogueHandler();
            }}
          >
            {downloadLoading ? <SimpleLoader size="size2" /> : "Download Data"}
          </CustomButton>
        </div>
        <CustomButton
          backgroundColor={colors.$primary}
          color="white"
          width="fit-content"
          borderRadius="8px"
          padding="1rem 1.5rem"
          disabled={isLoading || !orderUpdated}
          type="submit"
          height="1rem"
          onClick={() => {
            changeOrderSubmitHandler();
          }}
        >
          {isLoading ? <SimpleLoader size="size2" /> : "Enregistrer"}
        </CustomButton>
      </div>
      {!!errorMessage && (
        <div className={styles.errorContainer}>{errorMessage}</div>
      )}
      <div>
        <ResponsiveGridLayout
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 1 }}
          isBounded={true}
          isResizable={false}
          margin={[10, 80]}
          containerPadding={[0, 0]}
          compactType="vertical"
          onDragStop={(l) => {
            setTimeout(() => {
              changeLayoutHandler(l);
            }, 100);
          }}
          layouts={{ lg: productsCards }}
          draggableCancel=".allow-select"
        >
          {productsCards.map((productCard) => (
            <div
              key={productCard.i}
              data-grid={{
                x: productCard.x,
                y: productCard.y,
                w: productCard.w,
                h: productCard.h,
                static: productCard.static,
              }}
            >
              <ProductCard
                product={productCard.product}
                key={productCard.product.id}
                disabled={disabled}
                updateProductsSuccessHandler={updateProductsSuccessHandler}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};
