import { gql } from "@apollo/client";

import {
  graphqlGetFormulaFields,
  graphqlGetSubscriptionFields,
} from "~/constants/graphql";
import {
  SubscriptionCategory,
  SubscriptionPackage,
  UpdateSubscriptionCategoriesOrderInputObject,
  UpdateSubscriptionFormulasInputObject,
  UpdateSubscriptionProductsOrderInputObject,
} from "~/types/data/SubscriptionPackage.type";

export interface GetSubscriptionPackageByIdResponse {
  getSubscriptionPackageById: SubscriptionPackage;
}

export interface GetSubscriptionPackageByIdInput {
  GetSubscriptionPackageByIdInput: { subscriptionId: number };
}

export interface UpdateSubscriptionFormulasInput {
  UpdateSubscriptionFormulasInput: UpdateSubscriptionFormulasInputObject;
}

export interface UpdateSubscriptionCategoriesOrderInput {
  UpdateSubscriptionCategoriesOrderInput: UpdateSubscriptionCategoriesOrderInputObject;
}

export interface UpdateSubscriptionProductInput {
  UpdateSubscriptionProductInput: {
    subscriptionPackageConditionsId: number;
    points: number;
    minimumEngagement: number;
    secondHandPrice: number;
  };
}

export interface UpdateSubscriptionProductsOrderInput {
  UpdateSubscriptionProductsOrderInput: UpdateSubscriptionProductsOrderInputObject;
}

// responses
export interface UpdateSubscriptionCategoriesOrderResponse {
  updateSubscriptionCategoriesOrder: SubscriptionCategory[];
}

const subscriptionFields = graphqlGetSubscriptionFields.join(" ");

const getFormulaSelectFields = graphqlGetFormulaFields.join(" ");

export const GET_SUBSCRIPTION_PACKAGE_BY_ID = gql`
  query ($GetSubscriptionPackageByIdInput: GetSubscriptionPackageByIdInput!) {
    getSubscriptionPackageById(
      GetSubscriptionPackageByIdInput: $GetSubscriptionPackageByIdInput
    ) {
      ${subscriptionFields}

      formulas {
        ${getFormulaSelectFields}
      }

      products {
        id
        title
        image1
        image1FullPath
        priority
        parentId
        sku

        points
        minimumEngagement
        secondHandPrice
        subscriptionPackageConditionsId
        collectionName
        categoryId
        category2 {
          id
          name
        }
        category3 {
          id
          name
        }
      }

      subscriptionCategories {
        id
        categoryId
        priority
        name
      }

      lastSubscriptionImport {
        id
        createdAt
        filePath
        type
        status
        errorMessage
        totalEntries
        successfullEntries
      }
        
    }
  }
`;

export const UPDATE_SUBSCRIPTION_FORMULAS = gql`
  mutation (
    $UpdateSubscriptionFormulasInput: UpdateSubscriptionFormulasInput!
  ) {
    updateSubscriptionFormulas(
      UpdateSubscriptionFormulasInput: $UpdateSubscriptionFormulasInput
    ) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_CATEGORIES_ORDER = gql`
  mutation (
    $UpdateSubscriptionCategoriesOrderInput: UpdateSubscriptionCategoriesOrderInput!
  ) {
    updateSubscriptionCategoriesOrder(
      UpdateSubscriptionCategoriesOrderInput: $UpdateSubscriptionCategoriesOrderInput
    ) {
      id
      categoryId
      name
      priority
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PRODUCT = gql`
  mutation ($UpdateSubscriptionProductInput: UpdateSubscriptionProductInput!) {
    updateSubscriptionProduct(
      UpdateSubscriptionProductInput: $UpdateSubscriptionProductInput
    ) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PRODUCTS_ORDER = gql`
  mutation (
    $UpdateSubscriptionProductsOrderInput: UpdateSubscriptionProductsOrderInput!
  ) {
    updateSubscriptionProductsOrder(
      UpdateSubscriptionProductsOrderInput: $UpdateSubscriptionProductsOrderInput
    ) {
      id
    }
  }
`;
