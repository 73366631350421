import { utils, writeFile } from "xlsx";
import { Product } from "~/types/data/Product.types";

type downloadProductsType = {
  products: Product[];
  fileName: string;
};

export const downloadSubscriptionProducts = ({
  fileName,
  products,
}: downloadProductsType) => {
  const mappedProducts = products.map((p) => {
    const {
      id,
      title,
      category3,
      category2,
      sku,
      collectionName,
      priority,
      minimumEngagement,
      points,
      secondHandPrice,
    } = p;

    return {
      id,
      name: title,
      sku,
      category2: category2?.name,
      category3: category3?.name,
      thema: collectionName,
      minimum_duration: minimumEngagement,
      points,
      purchase_price: secondHandPrice,
      priority,
    };
  });

  // create file using xlsx
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(mappedProducts);
  const finalFileName = fileName.slice(0, 30);
  // make cells wrap text
  utils.book_append_sheet(wb, ws, finalFileName);
  writeFile(wb, `${finalFileName}.xlsx`);
};
